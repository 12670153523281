import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import "./alertDialog.css";

export const AlertDialog = ({
  open,
  onSubmit,
  onClose
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Você tem certeza que deseja finalizar? A partir daqui você não poderá voltar para a edição dos dados novamente.</DialogTitle>
      <DialogContent>
        <div className="div-destaque">
          <div className="destaque">Cheque as suas informações:</div><br></br>
          <div className="destaque">- Você informou o link do vídeo que desejava?</div>
          <div className="destaque">- O vídeo cumpre os requisitos do edital?</div>
          <div className="destaque">- Verificou se as imagens dos documentos estão legíveis?</div>
        </div>
        <DialogContentText>Suas informações seguirão para a análise de participante conforme regras do edital.</DialogContentText>
        <DialogContentText>Ao clicar em finalizar você concorda com os termos do edital FAMUS 2023.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} autoFocus>
          Voltar
        </Button>
        <Button color="secondary" onClick={() => {
          onSubmit();
          onClose();
        }}>
          Enviar e finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};