import React from "react";
import { useDropzone } from "react-dropzone";
import "./dropzone.css";

const Dropzone = ({ onDrop, accept, remove, durationVideo, tamanhoVideo }) => {
  var {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    maxFiles: 2,
    onDrop,
    accept,
  });

  var acceptedFileItems = acceptedFiles.map(
    (file) => file.path
    // `${file.path} - ${file.size} bytes`
  );

  var fileRejectionItems =
    fileRejections.length > 0
      ? fileRejections[0].errors[0].code === "too-many-files"
        ? "Envie apenas um arquivo"
        : "Formato de arquivo não permitido"
      : "";

  if (remove) {
    acceptedFiles.map((file) => acceptedFiles.splice(file, 1));
    fileRejections.map((file) => fileRejections.splice(file, 1));
  }

  return (
    <div {...getRootProps()} className="container-dropzone">
      <input {...getInputProps()} />
      <div>
        {isDragActive ? (
          <div className="text-aviso">Solte o arquivo aqui</div>
        ) : acceptedFileItems.length === 0 &&
          fileRejectionItems.length === 0 &&
          !remove &&
          !isDragActive ? (
          <div className="text">Clique aqui ou arraste o arquivo até aqui</div>
        ) : (
          <></>
        )}
      </div>
      {remove && !isDragActive ? (
        <div className="text">Clique aqui ou arraste o arquivo até aqui</div>
      ) : (
        <aside>
          {tamanhoVideo > 157286400 ? <div className="text-error">O vídeo não pode ultrapassar o tamanho de 150MB</div> :
            durationVideo > 300.000 ? <div className="text-error">O vídeo não pode ter mais de 5 minutos</div> :
            acceptedFileItems.length > 0 && !remove && !isDragActive ? (
              <div className="text-sucess">{acceptedFileItems}</div>
            ) : !isDragActive ? (
              <div className="text-error">{fileRejectionItems}</div>
            ) : (
              <></>
            )}
        </aside>
      )}
    </div>
  );
};

export default Dropzone;
