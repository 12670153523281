import "../../../Inscricao/inscricao.css";

const InputRadio = ({
  icone,
  label,
  tipo,
  tipoNome,
  setTipo,
  setTipoNome,
  option1,
  option2,
  erro,
}) => {
  return (
    <div>
      <div className="input-label">{label}</div>
      <div className="div-linha">
        {icone}
        <div className="radio">
          <input
            type="radio"
            value={tipo}
            checked={tipoNome === option1}
            onChange={() => {
              setTipo(true);
              setTipoNome(option1);
            }}
          />
          <p
            className={
              tipoNome === option1 ? "option-radio-selected" : "option-radio"
            }
          >
            {option1}
          </p>
        </div>
        <div className="radio">
          <input
            type="radio"
            value={tipo}
            checked={tipoNome === option2}
            onChange={() => {
              setTipo(true);
              setTipoNome(option2);
            }}
          />
          <p
            className={
              tipoNome === option2 ? "option-radio-selected" : "option-radio"
            }
          >
            {option2}
          </p>
        </div>
      </div>
      {erro}
    </div>
  );
};

export default InputRadio;
