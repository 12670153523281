import "../../../Inscricao/inscricao.css";

const InputDataNascimento = ({
  icone,
  tipoNome,
  dtNasc,
  setDtNasc,
  setErrorDtNasc,
  errorDtNasc,
  send,
}) => {

  return (
    <div>
      <div className="input-label">Data de nascimento</div>
      <div className="container-input">
        {icone}
        <input
          type="date"
          placeholder="Data de nascimento"
          value={dtNasc}
          onChange={(e) => {
            setDtNasc(e.target.value);
            if (e.target.value.length < 10) {
              setDtNasc(e.target.value);
              setErrorDtNasc(0);
            } else if (e.target.value.length === 10) {
              setDtNasc(e.target.value);
              setErrorDtNasc(2);
              /* var dtNasc = new Date(
                e.target.value.replace("-", ",").replace("-", ",")
              );
              var diffMilissegundos = new Date() - dtNasc;
              var diffSegundos = diffMilissegundos / 1000;
              var diffMinutos = diffSegundos / 60;
              var diffHoras = diffMinutos / 60;
              var diffDias = diffHoras / 24;
              var diffMeses = diffDias / 30;
              var diffAnos = diffMeses / 12;
              var ano = dtNasc.getFullYear();
              var anoTamanho = dtNasc.getFullYear().toString().length;

              if (diffAnos >= 18 && ano < 2023 && anoTamanho === 4) {
                setErrorDtNasc(2);
              } else {
                setErrorDtNasc(1);
              } */
            }
          }}
        />
      </div>
      {errorDtNasc === 0 && send ? <p>Preencha a data de nascimento</p> : <></>}
      {/* {errorDtNasc === 1 && send ? (
        <p>Inscrição permitida apenas para maiores de 18 anos</p>
      ) : (
        <></>
      )} */}
    </div>
  );
};

export default InputDataNascimento;
