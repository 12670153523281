import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import "./alertError.css";
import { RiCloseCircleFill } from "react-icons/ri";

export const AlertError = ({ open, response, onClose }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <div className={"div-alert"}>
          <RiCloseCircleFill color={'red'} size={34} className={"icon-alert"} />
          <div>Não foi possível realizar a inscrição</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Erro ao enviar dados</DialogContentText>
        <DialogContentText>
          {response !== undefined ? response.toString() : ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
