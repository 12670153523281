import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ReactLoading from "react-loading";
import "./loading.css";

export const Loading = ({ open}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Realizando a inscrição...</DialogTitle>
      <DialogContent>Aguarde... Pode demorar alguns minutos a depender do tamanho dos arquivos e da velocidade da sua internet.</DialogContent>
      <DialogContent>
        <div className="div-loading">
          <ReactLoading
            type={"spin"}
            color={"#27645e"}
            height={30}
            width={30}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
