import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useState, useCallback } from "react";
import "../../../Inscricao/inscricao.css";
import { AlertDialog } from "../AlertDialog";
import { AlertError } from "../AlertError";
import { AlertSucess } from "../AlertSucess";
import { Loading } from "../Loading";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Buttons = ({
  termo,
  setTermo,
  setOpenAlert,
  openAlert,
  setSend,
  tipo,
  musica,
  errorNome,
  errorNomeArtisitico,
  errorEmail,
  errorCPF,
  errorDtNasc,
  documentoFrente,
  documentoVerso,
  comprovante,
  letra,
  video,
  setErro,
  nome,
  nomeArtistico,
  email,
  cpf,
  dtNasc,
  setTipo,
  setTipoNome,
  setMusica,
  setMusicaTipo,
  setNome,
  setRemove,
  erro,
  setErrorNome,
  setNomeArtistico,
  setErrorNomeArtisitico,
  setEmail,
  setErrorEmail,
  setCPF,
  setErrorCPF,
  setDtNasc,
  setErrorDtNasc,
  setDocumentoFrente,
  setDocumentoVerso,
  setComprovante,
  setLetra,
  setVideo,
  tipoNome,
  musicaTipo,
  recaptcha,
  setRecaptcha,
  errorCelular,
  setCelular,
  fotoArtistica,
  setFotoArtistica,
  celular,
  setSucesso,
  durationVideo,
  token,
  setVideoData,
  setDurationVideo,
  tamanhoVideo,
  setTamanhoVideo,
  videoData,
  cidade,
  setCidade,
  setErrorCidade,
  errorCidade,
  linkVideo,
  setLinkVideo,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [response, setResponse] = useState();
  const [erroRecaptcha, setErroRecaptcha] = useState(false);

  const Enviar = async () => {
    setSend(true);
    setLoading(true);
    if (
      !tipo ||
      !musica ||
      errorNome !== 2 ||
      errorNomeArtisitico !== 2 ||
      errorEmail !== 2 ||
      errorCPF !== 2 ||
      errorDtNasc !== 2 ||
      errorCelular !== 2 ||
      documentoFrente.length === 0 ||
      documentoVerso.length === 0 ||
      comprovante.length === 0 ||
      letra.length === 0 ||
      linkVideo === "" ||
      fotoArtistica.length === 0 ||
      cidade.selectedOption === null ||
      !termo ||
      !recaptcha
      //durationVideo === undefined || //comentado por Gláuber
      //durationVideo > 300.000 || //comentado por Gláuber
      //tamanhoVideo > 157286400
    ) {
      setErro(true);
      setTimeout(function () {
        setErro(false);
      }, 5000);
      setLoading(false);
    } else {
      setSend(false);

      var cpf_data = cpf.replaceAll(".", "");
      cpf_data = cpf_data.replaceAll("-", "");
      var celular_data = celular.replaceAll("(", "");
      celular_data = celular_data.replaceAll(")", "");
      celular_data = celular_data.replaceAll(" ", "");
      celular_data = celular_data.replaceAll("-", "");
      celular_data = celular_data.substring(0, 11);
      var dtNasc_data =
        dtNasc.split("-")[2] +
        "/" +
        dtNasc.split("-")[1] +
        "/" +
        dtNasc.split("-")[0];

      axios
        .post("http://18.231.95.13/api/apiInscricao.rule?sys=APP", {
          tipoMusica: tipoNome === "Dupla/Banda" ? "D" : "S",
          categoriaMusical: musicaTipo === "Autoral" ? "A" : "I",
          nomeResponsavel: nome,
          nomeArtistico: nomeArtistico,
          responsavelEmail: email,
          responsavelCelular: celular_data,
          responsavelCpf: cpf_data,
          responsavelNascimento: dtNasc_data,
          documentoFrente: documentoFrente,
          documentoVerso: documentoVerso,
          fotoArtistica: fotoArtistica,
          comprovanteResidencia: comprovante,
          letraMusica: letra,
          linkVideo: linkVideo,
          cdCidade: cidade.selectedOption.value,
          concordaTermo: termo,
          token: token,
        })
        .then((resp) => {
          setResponse(resp);
          setSucess(true);
          setLoading(false);
          //Limpar();
        })
        .catch((error) => {
          setError(true);
          setResponse(error);
          setLoading(false);
        });
    }
  };

  function closeAlertError() {
    setError(false);
  }

  function closeAlertSucess() {
    setSucess(false);
  }

  function closeAlert() {
    setOpenAlert(false);
  }

  function Limpar() {
    setTipo(false);
    setTipoNome("");
    setMusica(false);
    setMusicaTipo("");
    setNome("");
    setErrorNome(0);
    setNomeArtistico("");
    setErrorNomeArtisitico(0);
    setErrorEmail(0);
    setCPF("");
    setErrorCPF(0);
    setDtNasc("");
    setErrorDtNasc(0);
    setErrorCidade(0);
    setDocumentoFrente([]);
    setDocumentoVerso([]);
    setComprovante([]);
    setLetra([]);
    setVideo([]);
    setSend(false);
    setRemove(true);
    setTermo(false);
    setRecaptcha(false);
    setCelular("");
    setCidade({ selectedOption: null });
    setFotoArtistica([]);
    setVideoData(false);
    setDurationVideo();
    setTamanhoVideo(157286400);
    setLinkVideo("");
    setTimeout(function () {
      setRemove(false);
    }, 5000);
  }

  return (
    <div className="div-margin">
      <div className="div-buttons">
        <button
          type="submit"
          onClick={() => {
            Limpar();
          }}
        >
          Limpar
        </button>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LdYqSMcAAAAAJY9vJe3yXZkqNwjcVqRuX-OYMVF"
          className="grecaptcha-badge"
        >
          <ReCaptchaComponent
            setOpenAlert={setOpenAlert}
            setRecaptcha={setRecaptcha}
            setErroRecaptcha={setErroRecaptcha}
          />
        </GoogleReCaptchaProvider>
      </div>
      {loading ? <Loading open={loading} /> : <></>}
      {error ? (
        <AlertError
          open={error}
          response={response}
          onClose={closeAlertError}
        />
      ) : (
        <></>
      )}
      {sucess ? (
        <AlertSucess
          open={sucess}
          response={response}
          onClose={closeAlertSucess}
          setSucesso={setSucesso}
        />
      ) : (
        <></>
      )}
      {openAlert ? (
        <AlertDialog open={openAlert} onSubmit={Enviar} onClose={closeAlert} />
      ) : (
        <></>
      )}
      {erro && !erroRecaptcha ? (
        <span>
          <Alert variant="filled" severity="error">
            Por favor preencha todos os dados corretamente.
          </Alert>{" "}
        </span>
      ) : (
        <></>
      )}
      {erroRecaptcha ? (
        <span>
          <Alert variant="filled" severity="error">
            Não foi possível validar o reCAPTCHA.
          </Alert>{" "}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Buttons;

const ReCaptchaComponent = ({
  setOpenAlert,
  setRecaptcha,
  setErroRecaptcha,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(() => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    (async () => {
      try {
        await executeRecaptcha("senddata");
        setRecaptcha(true);
        setOpenAlert(true);
        setErroRecaptcha(false);
      } catch (error) {
        setErroRecaptcha(true);
        setTimeout(function () {
          setErroRecaptcha(false);
        }, 5000);
        console.log(error.response);
      }
    })();
  }, [executeRecaptcha, setOpenAlert, setRecaptcha, setErroRecaptcha]);

  return (
    <button
      onClick={() => {
        handleReCaptchaVerify();
      }}
    >
      Enviar
    </button>
  );
};
