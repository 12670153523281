import { validateEmail } from "validations-br";
import "../../../Inscricao/inscricao.css";

const InputEmail = ({
  icone,
  value,
  setEmail,
  setErrorEmail,
  errorEmail,
  send,
  erro1,
  erro2,
}) => {
  return (
    <div>
      <div className="input-label">E-mail</div>
      <div className="container-input">
        {icone}
        <input
          type="email"
          placeholder="E-mail"
          value={value}
          disabled
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setEmail(e.target.value);
              setErrorEmail(0);
            } else if (validateEmail(e.target.value)) {
              setEmail(e.target.value);
              setErrorEmail(2);
            } else {
              setEmail(e.target.value);
              setErrorEmail(1);
            }
          }}
        />
      </div>
      {errorEmail === 0 && send ? erro1 : <></>}
      {errorEmail === 1 && send ? erro2 : <></>}
    </div>
  );
};

export default InputEmail;
