import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import "./alertSucess.css";

export const AlertSucess = ({ open, response, onClose, setSucesso }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <div className={"div-alert"}>
          {response !== undefined ? response.data.status === 'success' ?
            <FaCheckCircle color={'#27645e'} size={30} className={"icon-alert"} /> :
            <MdError color={'#EEAD2D'} size={34} className={"icon-alert"} /> : <></>}
          <div>{response !== undefined ? response.data.message : ""}</div>
        </div>
      </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={() => {
          if (response !== undefined && response.data.status === 'success') {
            setSucesso(true);
          }
          onClose();
        }} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
