import "../../../Inscricao/inscricao.css";
import Dropzone from "../Dropzone";

const InputDropzone = ({
  icone,
  label,
  setFile,
  accept,
  remove,
  file,
  send,
  erro,
  setVideoData,
  durationVideo,
  videoData,
  tamanhoVideo,
  setTamanhoVideo,
  ehVideo
}) => {
  return (
    <div>
      <div className="input-label">{label}</div>
      <div className="container-input">
        {icone}
        <Dropzone
          onDrop={(file) => {
            if (file.length > 0) {
              if(ehVideo){
                setTamanhoVideo(file[0].size)
              }
              var reader = new FileReader();
              reader.readAsDataURL(file[0]);
              reader.onload = (event) => {
                setFile(event.target.result);
              };
            }
            if (setVideoData !== undefined) {
              setVideoData(file[0]);
            }
          }}
          accept={accept}
          remove={remove}
          durationVideo={durationVideo}
          tamanhoVideo={tamanhoVideo}
        />
      </div>
      {(file.length === 0 || (videoData === false && durationVideo === undefined) || (videoData === false && durationVideo > 300.000)) && send ? <p>{erro}</p> : <></>}
    </div>
  );
};

export default InputDropzone;
