import { validateCPF } from "validations-br";
import "../../../Inscricao/inscricao.css";

const InputCPF = ({ icone, cpf, setCPF, setErrorCPF, errorCPF, send }) => {
  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  return (
    <div>
      <div className="input-label">CPF</div>
      <div className="container-input">
        {icone}
        <input
          type="text"
          placeholder="CPF"
          value={cpfMask(cpf)}
          onChange={(e) => {
            var valid;
            if (e.target.value.length === 0) {
              setCPF("");
              setErrorCPF(0);
            } else if (e.target.value.length < 14) {
              setCPF(e.target.value);
              valid = validateCPF(cpfMask(e.target.value));
              setErrorCPF(1);
            } else {
              valid = validateCPF(cpfMask(e.target.value));
              setCPF(e.target.value);
              if (valid) {
                setErrorCPF(2);
              } else {
                setErrorCPF(1);
              }
            }
          }}
        />
      </div>
      {errorCPF === 0 && send ? <p>Preencha o CPF</p> : <></>}
      {errorCPF === 1 && send ? <p>CPF inválido</p> : <></>}
    </div>
  );
};

export default InputCPF;
