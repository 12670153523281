import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import "./alertSucess.css";

export const AlertSucessToken = ({ open, response, onClose, setSucesso }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <div className={"div-alert"}>
          {response !== undefined ? response.data.status === 'success' ?
            <FaCheckCircle color={'#27645e'} size={30} className={"icon-alert"} /> :
            <MdError color={'#EEAD2D'} size={34} className={"icon-alert"} /> : <></>}
          <div>{response !== undefined ? response.data.message : ""}</div>
        </div>
      </DialogTitle>
        <div style={{ padding: 15 }}>
      <DialogContentText>
          Por favor faça seu login novamente
      </DialogContentText>
      </div>
      <DialogActions>
        <a href="https://famus.unimedsudoeste.com.br/" className="link">
          <Button color="primary" onClick={() => {
              if (response !== undefined && response.data.status === 'success') {
                setSucesso(true);
              }
            onClose();
          }} autoFocus>
            OK
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
};
