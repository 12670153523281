import "../../../Inscricao/inscricao.css";

const InputCel = ({
  icone,
  tipoNome,
  label1,
  label2,
  value,
  setValue,
  setError,
  erro,
  send,
  erro1,
  erro2,
  erro3,
}) => {
  function phoneMask(v) {
    let r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (v.trim() !== "") {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  return (
    <div>
      <div className="input-label">{tipoNome === "Solo" ? label1 : label2}</div>
      <div className="container-input">
        {icone}
        <input
          type="nome"
          placeholder={tipoNome === "Solo" ? label1 : label2}
          value={phoneMask(value)}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setValue(e.target.value);
              setError(0);
            } else if (e.target.value.length >= 15) {
              setValue(e.target.value);
              setError(2);
            } else {
              setValue(e.target.value);
              setError(1);
            }
          }}
        />
      </div>
      {erro === 0 && send ? (
        <p>{tipoNome === "Solo" ? erro1 : erro2}</p>
      ) : (
        <></>
      )}
      {erro === 1 && send ? <p> {erro3} </p> : <></>}
    </div>
  );
};

export default InputCel;
