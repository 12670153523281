import "../../../Inscricao/inscricao.css";
import Select from "react-select";
import React from "react";

const InputSelect = ({
  label,
  setCidade,
  erro,
  send,
  errorCidade,
  icone,
  setError,
  cidade,
}) => {
  const options = [
    {
      value: "dca0e268c6d849418d82b9ba922fb6bd",
      label: "AIQUARA",
    },
    {
      value: "780bb19d76184ff4b7666ef9c618457f",
      label: "ANAGE",
    },
    {
      value: "f510ae348b304e2f9f445d83748c8941",
      label: "APUAREMA",
    },
    {
      value: "029cc1a7605b460a9773db23524d3fdc",
      label: "ARACATU",
    },
    {
      value: "16248b11c68e47b282808c1ebf5143f4",
      label: "BARRA DA ESTIVA",
    },
    {
      value: "1d5ef49976ee4433843ffb486de3fa43",
      label: "BARRA DO CHOCA",
    },
    {
      value: "ad6ab7c15e534947a642ca134042569b",
      label: "BARRA DO ROCHA",
    },
    {
      value: "1c93684f05774e828b6af337f6606ec6",
      label: "BELO CAMPO",
    },
    {
      value: "46125e12f9a7414693c5cdd824cc8ac5",
      label: "BOA NOVA",
    },
    {
      value: "5bb49c7886874f68ae48248ec6ef215a",
      label: "BOM JESUS DA LAPA",
    },
    {
      value: "2093aea854be4521a891a6e76be67d77",
      label: "BOM JESUS DA SERRA",
    },
    {
      value: "7f3c8f5a7ab04a9ab16ba50fb2fc4013",
      label: "BOQUIRA",
    },
    {
      value: "dc5a8eb1f1494023b676f2d9577457e1",
      label: "BOTUPORA",
    },
    {
      value: "90adbc9e28764d3791285ad98aebe65b",
      label: "BREJOES",
    },
    {
      value: "638f47b7e8524d93aad0902b836741f3",
      label: "BRUMADO",
    },
    {
      value: "755eac625e43431b8c62383b9d1bf790",
      label: "CAATIBA",
    },
    {
      value: "e0cd0fb88be44381904203a2e2442010",
      label: "CACULE",
    },
    {
      value: "3acbb229a0a54a42855de0f3ea3fb5a6",
      label: "CAETANOS",
    },
    {
      value: "fe5fa91e311449c99980812a05c72dc0",
      label: "CAETITE",
    },
    {
      value: "6204820a713f464db46d1eb3161b5394",
      label: "CANDIBA",
    },
    {
      value: "997c5c80cb9f402b9bf7cf5a5d5d0ca3",
      label: "CANDIDO SALES",
    },
    {
      value: "42541c95b55c42e9ba0360610d3c229f",
      label: "CARAIBAS",
    },
    {
      value: "c8fed913fcfe4f519e97b4094528c394",
      label: "CARINHANHA",
    },
    {
      value: "fee62089bd2c422a9f7aec338d106eff",
      label: "CATURAMA",
    },
    {
      value: "b1ee606b80c242529c77602e36369aca",
      label: "CONDEUBA",
    },
    {
      value: "31f357f274e940e48974665c2467bf89",
      label: "CONTENDAS DO SINCORA",
    },
    {
      value: "89454a500f7543018b9a5a9f06ea84cc",
      label: "CORDEIROS",
    },
    {
      value: "7189b38b8ae5499d9b27268a3d25355f",
      label: "CRAVOLANDIA",
    },
    {
      value: "a105b933bf4f461ba90cb4b8edacf6c8",
      label: "DARIO MEIRA",
    },
    {
      value: "07495abb77ac454dbee129617fa154f6",
      label: "DOM BASILIO",
    },
    {
      value: "2401e7a3e4c24002b400db99f66afc7b",
      label: "ENCRUZILHADA",
    },
    {
      value: "4e03f67c4c4d4af4bb846f94248d2028",
      label: "ERICO CARDOSO",
    },
    {
      value: "14278b62d7794769aca4e2bed1196904",
      label: "FEIRA DA MATA",
    },
    {
      value: "182ecc9374724332abc1703a8503c032",
      label: "GUAJERU",
    },
    {
      value: "b3e4ca84c5c9456fa7a51f59cfb04380",
      label: "GUANAMBI",
    },
    {
      value: "a96462e62ba24424b714d674c46484c5",
      label: "IBIASSUCE",
    },
    {
      value: "48b09d7aef334541a7a54e73ac8f7912",
      label: "IBICOARA",
    },
    {
      value: "daf9d2355ad64dcb982651a88b7f7528",
      label: "IBICUI",
    },
    {
      value: "90404c7704114a94aae54c97b97c285b",
      label: "IBIPITANGA",
    },
    {
      value: "f1a328fef0f04d129c9a97405d6e0f59",
      label: "IBIRATAIA",
    },
    {
      value: "6cc80305fc9742cbb1f90ff0113d3623",
      label: "IGAPORA",
    },
    {
      value: "4f9bc07494b44f53b9d3fa3f68129ff6",
      label: "IGUAI",
    },
    {
      value: "6b5f6623d5dc475aa9a8b5c3c9f49b9d",
      label: "IPIAU",
    },
    {
      value: "454c04875a8946479734cbb229335eb5",
      label: "IRAJUBA",
    },
    {
      value: "8fc25dce50ed4d0c9ea4cd1a02ed71cb",
      label: "IRAMAIA",
    },
    {
      value: "fddf287683d74677853094e98235335e",
      label: "ITAGI",
    },
    {
      value: "161d4d41ad7c46cfbc8b692e8dc430c5",
      label: "ITAGIBA",
    },
    {
      value: "2cc3283a58074c3cb105c1106b2a2941",
      label: "ITAMBE",
    },
    {
      value: "6f0bd91c5c6744858c7337a80040be32",
      label: "ITAPETINGA",
    },
    {
      value: "6d0a6ea85840435cadafa09df84fca27",
      label: "ITAQUARA",
    },
    {
      value: "7681515185d049e29baf24b0139f7f02",
      label: "ITARANTIM",
    },
    {
      value: "5231452da2d945a9a4bc2a9c85389575",
      label: "ITIRUCU",
    },
    {
      value: "03d1c7cd918e4477a975bda5037a7bac",
      label: "ITUACU",
    },
    {
      value: "9de55bf375b146d6a1c34ae5fe2eedb0",
      label: "IUIU",
    },
    {
      value: "169378ad3dc844fa831cbb2b395c7d3c",
      label: "JACARACI",
    },
    {
      value: "16f8dee9619d4a889ffbda2d460ffce2",
      label: "JAGUAQUARA",
    },
    {
      value: "79fed5a34d0241daa60cdde9af63b21b",
      label: "JEQUIE",
    },
    {
      value: "3be53d99ad8b4106b8097b64ba00c4fd",
      label: "JITAUNA",
    },
    {
      value: "8bd66021f71d4a73b0a7f8bb8205aab9",
      label: "JUSSIAPE",
    },
    {
      value: "fa4fb81ec41a48eab2cff2fdb687a5e5",
      label: "LAFAIETE COUTINHO",
    },
    {
      value: "010acd21247144de84939114ee1bf9e9",
      label: "LAGOA REAL",
    },
    {
      value: "d454642ae748431f9e847ddae83c930c",
      label: "LAJEDO DO TABOCAL",
    },
    {
      value: "2fd5f10f76bb4ad6a80f705652d83c66",
      label: "LICINIO DE ALMEIDA",
    },
    {
      value: "f141befccc2843138791c81c8290ae09",
      label: "LIVRAMENTO DE NOSSA SENHORA",
    },
    {
      value: "d5a31c45cc3a48f38d3fd070412f4b32",
      label: "MACARANI",
    },
    {
      value: "56125fe3505f4afcacdf3bf05bc01d2b",
      label: "MACAUBAS",
    },
    {
      value: "c1b6ffd1078b47fdb617aed99d01f402",
      label: "MAETINGA",
    },
    {
      value: "b5d44f9b4d9f45eb89d1ff9ca83a243e",
      label: "MAIQUINIQUE",
    },
    {
      value: "64fb4f7a34914ad88de731a4665d859f",
      label: "MALHADA",
    },
    {
      value: "53632eb1583c47bf82d64f96d51f4c58",
      label: "MALHADA DE PEDRAS",
    },
    {
      value: "6d749239b39345c48a78fc818f6df14f",
      label: "MANOEL VITORINO",
    },
    {
      value: "10b4189de35a474aa68d8765dfd81051",
      label: "MARACAS",
    },
    {
      value: "08c0cefa347c48d3acce578af6a3f049",
      label: "MATINA",
    },
    {
      value: "937ba7f2dd5a491c87816ffbfa86f187",
      label: "MILAGRES",
    },
    {
      value: "cff790940557495f86f60ce0f4edd59f",
      label: "MIRANTE",
    },
    {
      value: "cf0d609a7e0e4601860973484e105e7d",
      label: "MORTUGABA",
    },
    {
      value: "b1e9327a3456413992afb37321381923",
      label: "NOVA CANAA",
    },
    {
      value: "6a91f91893be43e99c1b2b8c8e514ec3",
      label: "NOVA ITARANA",
    },
    {
      value: "d984de05c0b04136a06dd82b7692af71",
      label: "PALMAS DE MONTE ALTO",
    },
    {
      value: "075c4d52a23c42ae8f59304eae63a92a",
      label: "PARAMIRIM",
    },
    {
      value: "89f6a49707b24be5b49dac428a58019f",
      label: "PINDAI",
    },
    {
      value: "75442ccb1dba4aedb8ee402a16bb4853",
      label: "PIRIPA",
    },
    {
      value: "a8f80bc2fa5144d298a5637dcbba728a",
      label: "PLANALTINO",
    },
    {
      value: "c4a6ecb1851b44c2b5c832db7659e322",
      label: "PLANALTO",
    },
    {
      value: "f9bb9acc0b944520ac6c5f823ba86f1d",
      label: "POCOES",
    },
    {
      value: "4cea5bf877574510816af7e46785c6df",
      label: "POTIRAGUA",
    },
    {
      value: "7cf67c60a067454288acb1f4c47fc577",
      label: "PRESIDENTE JANIO QUADROS",
    },
    {
      value: "992d21d0a6a644f5bb32008d505671bf",
      label: "RIACHO DE SANTANA",
    },
    {
      value: "9bb00cf3a49a4c11ae365564bed59ab8",
      label: "RIBEIRAO DO LARGO",
    },
    {
      value: "2e56155e8e7f47499be626112540d222",
      label: "RIO DE CONTAS",
    },
    {
      value: "4a5a61cc3cfb461fa2df24a69c223bca",
      label: "RIO DO ANTONIO",
    },
    {
      value: "75b3a014932c46e4b2ec620c18557cc4",
      label: "RIO DO PIRES",
    },
    {
      value: "fc91af2cd99746dca8f3c1040e761375",
      label: "SANTA INES",
    },
    {
      value: "210ee6853dc44a3db570695d54b1490e",
      label: "SEBASTIAO LARANJEIRAS",
    },
    {
      value: "100e0c530f9d43428057fea43be0a42d",
      label: "SERRA DO RAMALHO",
    },
    {
      value: "41d61776e592420b8e28f4b4d92e5df2",
      label: "TANHACU",
    },
    {
      value: "f2648bacff094382b1daacc2af86660f",
      label: "TANQUE NOVO",
    },
    {
      value: "90ad6cc077e1468eb94866bdf2ad4e54",
      label: "TREMEDAL",
    },
    {
      value: "9df2ad75a3d84e85a4e9db594a4eeddf",
      label: "UBAIRA",
    },
    {
      value: "7898ee57f513419b82b492e29f452a87",
      label: "UBATA",
    },
    {
      value: "e66ae932b28948798ebd074bd3ca4d51",
      label: "URANDI",
    },
    {
      value: "0de00ece91fe4036b67ba710db838eab",
      label: "VITORIA DA CONQUISTA",
    },
  ];

  const handleChange = (selectedOption) => {
    setCidade({ selectedOption });
    setError(1);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
  };

  return (
    <div>
      <div className="input-label">{label}</div>
      <div className="teste">
        <div className="container-input2">{icone}</div>
        <Select
          className="input-select"
          styles={style}
          value={cidade.selectedOption}
          onChange={handleChange}
          options={options}
          placeholder={"Escolha a cidade de residência"}
        />
      </div>
      {errorCidade === 0 && send ? <p>{erro}</p> : <></>}
    </div>
  );
};

export default InputSelect;
