import { GoLinkExternal } from "react-icons/go";
import "../../../Inscricao/inscricao.css";
//import edital from "../../../images/edital.pdf";

const InputCheckbox = ({ termo, send, setTermo }) => {
  return (
    <div>
      <div className="termos">
        <input
          type="checkbox"
          checked={termo}
          onChange={() => {
            setTermo(!termo);
          }}
        />
        <label className="labelcheckbox">
          Li e concordo com os termos do{" "}
          <a
            href={"https://famus.unimedsudoeste.com.br/2023/edital.pdf"}
            className="link"
            target="blank"
          >
            edital
          </a>
        </label>
        <label> </label>
        <GoLinkExternal />
      </div>
      {!termo && send ? (
        <p>É necessário ler e concordar com os termos do edital</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputCheckbox;
