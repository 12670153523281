import React, { useState, useEffect } from "react";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { FaCheckCircle } from "react-icons/fa";
import { FaHourglassHalf } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import "./statusTimeline.css";

export default function StatusTimeline({ email, dados }) {
  const [data, setData] = useState(dados);
  useEffect(() => {
    axios
      .post(
        "http://18.231.95.13/api/apiStatusInscricao.rule?sys=APP",
        {
          email: email
        }
      )
      .then((response) => {
        if (response.data.length > 0) {
          setData(response.data[0])
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }, []);
  return (
    <>
      {data !== undefined &&
        <Timeline align="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <FaCheckCircle color={'#27645e'} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div className="label">Aguarde!!! Sua Inscrição foi recebida e as informações estão sendo validadas por nossa equipe.</div>
            </TimelineContent>
          </TimelineItem>
        </Timeline >
      }</>
  );
}
