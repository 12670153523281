import "../../../Inscricao/inscricao.css";

const InputText = ({
  icone,
  tipoNome,
  label1,
  label2,
  label3,
  value,
  setValue,
  setError,
  erro,
  send,
  erro1,
  erro2,
  erro3,
}) => {
  return (
    <div>
      <div className="input-label">{tipoNome === "Solo" ? label1 : label2}</div>
      <div className="container-input">
        {icone}
        <input
          type="nome"
          placeholder={tipoNome === "Solo" ? label1 : tipoNome === "Video" ? label3 : label2}
          value={value}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setValue(e.target.value);
              setError(0);
            } else if (e.target.value.length > 5) {
              setValue(e.target.value);
              setError(2);
            } else {
              setValue(e.target.value);
              setError(1);
            }
          }}
        />
      </div>
      {erro === 0 && send ? (
        <p>{tipoNome === "Solo" ? erro1 : erro2}</p>
      ) : (
        <></>
      )}
      {erro === 1 && send ? <p> {erro3} </p> : <></>}
    </div>
  );
};

export default InputText;
