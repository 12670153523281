import { Container, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  BsCameraVideoFill,
  BsCardHeading,
  BsMusicNoteList,
  BsPeople,
  BsPerson,
  BsPersonSquare,
} from "react-icons/bs";
import {
  FaMicrophoneAlt,
  FaMusic,
  FaRegAddressCard,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { MdEmail, MdArrowBack, MdLocationOn } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import logo from "../images/festival-unimed.png";
import Buttons from "./Components/Buttons";
import InputCel from "./Components/InputCel";
import InputCheckbox from "./Components/InputCheckbox";
import InputCPF from "./Components/InputCPF";
import InputDataNascimento from "./Components/InputDataNascimento";
import InputDropzone from "./Components/InputDropzone";
import InputEmail from "./Components/InputEmail";
import InputRadio from "./Components/InputRadio";
import InputText from "./Components/InputText";
import InputSelect from "./Components/InputSelect";
import StatusTimeline from "./Components/StatusTimeline";
import "./inscricao.css";
import axios from "axios";
//import ReactLoading from "react-loading";
import { AlertSucessToken } from "./Components/AlertSucessToken";
import { AlertErrorToken } from "./Components/AlertErrorToken";

const Inscricao = () => {
  const [linkVideo, setLinkVideo] = useState("");
  const [cidade, setCidade] = useState({
    selectedOption: null,
  });
  const [nome, setNome] = useState("");
  const [nomeArtistico, setNomeArtistico] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [dtNasc, setDtNasc] = useState("");
  const [documentoFrente, setDocumentoFrente] = useState([]);
  const [documentoVerso, setDocumentoVerso] = useState([]);
  const [comprovante, setComprovante] = useState([]);
  const [letra, setLetra] = useState([]);
  const [video, setVideo] = useState(false);
  const [fotoArtistica, setFotoArtistica] = useState([]);
  const [remove, setRemove] = useState(false);
  const [errorNomeArtisitico, setErrorNomeArtisitico] = useState(0);
  const [errorLinkVideo, setErrorLinkVideo] = useState(0);
  const [errorCidade, setErrorCidade] = useState(0);
  const [errorNome, setErrorNome] = useState(0);
  const [errorDtNasc, setErrorDtNasc] = useState(0);
  const [errorEmail, setErrorEmail] = useState(2);
  const [errorCPF, setErrorCPF] = useState(0);
  const [erro, setErro] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [send, setSend] = useState(false);
  const [tipo, setTipo] = useState(false);
  const [tipoNome, setTipoNome] = useState(false);
  const [musica, setMusica] = useState(false);
  const [musicaTipo, setMusicaTipo] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [termo, setTermo] = useState(false);
  const [celular, setCelular] = useState("");
  const [errorCelular, setErrorCelular] = useState(0);
  const [sucesso, setSucesso] = useState(false);
  const [durationVideo, setDurationVideo] = useState();
  const [videoData, setVideoData] = useState(false);
  //const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [response, setResponse] = useState();
  const [token, setToken] = useState();
  const [tamanhoVideo, setTamanhoVideo] = useState(157286400);
  const [dataStatus, setDataStatus] = useState();

  function closeAlertError() {
    setError(false);
  }

  function closeAlertSucess() {
    setSucess(false);
  }

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    console.log('token', token);
    setToken(token);
    axios
      .post("http://18.231.95.13/api/apiToken.rule?sys=APP", {
        token: token,
      })
      .then((response) => {
        console.log('response');
        setResponse(response);
        setEmail(response.data.email);
        if (response.data.status === "error") {
          setSucess(true);
        } else {
          axios
            .post(
              "http://18.231.95.13/api/apiStatusInscricao.rule?sys=APP",
              {
                email: response.data.email,
              }
            )
            .then((response) => {
              console.log('response2');
              setResponse(response);
              if (response.data.length > 0) {
                setDataStatus(response.data[0]);
              }
            })
            .catch((error) => {
              setResponse(error);
              setError(true);
            });

          //setData(true);
          setSucesso(response.data.inscrito);
        }
      })
      .catch((error) => {
        setResponse(error);
        setError(true);
      });
  }, []);

  return (
    <div className="div-coluna">
      <div className={"logo-topo"}>
        <img src={logo} alt="Festival Unimed Sudoeste de Música" />
      </div>

      {error ? (
        <AlertErrorToken
          open={error}
          response={response}
          onClose={closeAlertError}
        />
      ) : (
        <></>
      )}
      {sucess ? (
        <AlertSucessToken
          open={sucess}
          response={response}
          onClose={closeAlertSucess}
          setSucesso={setSucesso}
        />
      ) : (
        <></>
      )}

      {videoData && (
        <video
          className="div-video"
          width="250"
          onLoadedMetadata={(e) => {
            setDurationVideo(e.target.duration);
            setVideoData(false);
          }}
        >
          <source src={URL.createObjectURL(videoData)} />
        </video>
      )}

      <div className="container">
        <div className="logo">
          <img src={logo} alt="Festival Unimed Sudoeste de Música" />
        </div>
        {sucesso ? (
          <div className="container-right">
            <div className="div-buttons-voltar">
              <a href="https://famus.unimedsudoeste.com.br/" className="link">
                <button type="submit" onClick={() => {}}>
                  <MdArrowBack />
                  <div>Voltar</div>
                </button>
              </a>
            </div>
            <div className="titulo">Você já está inscrito!</div>
            <div className="subtitulo-inscricao">
              Segue abaixo o status da sua inscrição:
            </div>
            <StatusTimeline email={email} dados={dataStatus} />
          </div>
        ) : (
          <div className="container-right">
            <div className="div-buttons-voltar">
              <a href="https://famus.unimedsudoeste.com.br/" className="link">
                <button type="submit" onClick={() => {}}>
                  <MdArrowBack />
                  <div>Voltar</div>
                </button>
              </a>
            </div>
            <div className="titulo">
              Preencha os dados abaixo para se inscrever
            </div>
            <div className="div-subtitulo">
              <div className="subtitulo">
                Você tem até as 23h59 (horário de Brasília) do dia 20/09/2023
                para enviar sua inscrição
              </div>
              {/* <div className="subtitulo">
                Análise das inscrições até 22/09/2021
              </div>
              <div className="subtitulo">Resultados até 28/09/2021</div> */}
            </div>
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <InputRadio
                    icone={<FaMusic />}
                    label="Categoria"
                    tipo={musica}
                    tipoNome={musicaTipo}
                    setTipo={setMusica}
                    setTipoNome={setMusicaTipo}
                    option1="Autoral"
                    option2="Interprete"
                    erro={
                      !musica && send ? <p>Informe o tipo da música</p> : <></>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputRadio
                    icone={<FaMicrophoneAlt />}
                    label="Música"
                    tipo={tipo}
                    tipoNome={tipoNome}
                    setTipo={setTipo}
                    setTipoNome={setTipoNome}
                    option1="Solo"
                    option2="Dupla/Banda"
                    send={send}
                    erro={!tipo && send ? <p>Informe a categoria</p> : <></>}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputText
                    icone={<BsPerson />}
                    tipoNome={tipoNome}
                    label1="Nome do candidato"
                    label2="Nome do responsável"
                    value={nome}
                    setValue={setNome}
                    setError={setErrorNome}
                    erro={errorNome}
                    send={send}
                    erro1="Preencha o nome do candidato"
                    erro2="Preencha o nome do responsável"
                    erro3="Nome muito curto"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputText
                    icone={<BsPeople />}
                    tipoNome={tipoNome}
                    label1="Nome artístico do cantor"
                    label2="Nome da dupla ou banda"
                    value={nomeArtistico}
                    setValue={setNomeArtistico}
                    setError={setErrorNomeArtisitico}
                    erro={errorNomeArtisitico}
                    send={send}
                    erro1="Preencha o nome artístico do cantor"
                    erro2="Preencha o nome da dupla ou banda"
                    erro3="Nome muito curto"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputEmail
                    icone={<MdEmail />}
                    value={email}
                    setEmail={setEmail}
                    setErrorEmail={setErrorEmail}
                    errorEmail={errorEmail}
                    send={send}
                    erro1={<p>Preencha o e-mail</p>}
                    erro2={<p>E-mail inválido</p>}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputCel
                    icone={<FiPhoneCall />}
                    tipoNome={tipoNome}
                    label1="Celular do candidato"
                    label2="Celular do responsável"
                    value={celular}
                    setValue={setCelular}
                    setError={setErrorCelular}
                    erro={errorCelular}
                    send={send}
                    erro1="Preencha o celular do candidato"
                    erro2="Preencha o celular do responsável"
                    erro3="Número incompleto, informe corretamente"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputCPF
                    icone={<BsCardHeading />}
                    cpf={cpf}
                    setCPF={setCPF}
                    setErrorCPF={setErrorCPF}
                    errorCPF={errorCPF}
                    send={send}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDataNascimento
                    icone={<FaRegCalendarAlt />}
                    tipoNome={tipoNome}
                    dtNasc={dtNasc}
                    setDtNasc={setDtNasc}
                    setErrorDtNasc={setErrorDtNasc}
                    errorDtNasc={errorDtNasc}
                    send={send}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDropzone
                    icone={<FaRegAddressCard />}
                    label="Documento com foto (Frente)"
                    setFile={setDocumentoFrente}
                    accept={"image/*"}
                    remove={remove}
                    file={documentoFrente}
                    send={send}
                    erro="Anexe um documento com foto (Frente)"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDropzone
                    icone={<BsCardHeading />}
                    label="Documento com foto (Verso)"
                    setFile={setDocumentoVerso}
                    accept={"image/*"}
                    remove={remove}
                    file={documentoVerso}
                    send={send}
                    erro="Anexe um documento com foto (Verso)"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputSelect
                    icone={<MdLocationOn />}
                    label="Cidade onde reside"
                    cidade={cidade}
                    setCidade={setCidade}
                    setError={setErrorCidade}
                    errorCidade={errorCidade}
                    send={send}
                    erro="Informe a cidade onde reside"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDropzone
                    icone={<TiDocumentText />}
                    label="Comprovante de residência"
                    setFile={setComprovante}
                    accept={"image/*"}
                    remove={remove}
                    file={comprovante}
                    send={send}
                    erro="Anexe um comprovante de residência"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDropzone
                    icone={<BsMusicNoteList />}
                    label="Letra da música (Formato: PDF)"
                    setFile={setLetra}
                    accept={".pdf"}
                    remove={remove}
                    file={letra}
                    send={send}
                    erro="Anexe a letra da música (Formatop: PDF)"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputText
                    icone={<BsCameraVideoFill />}
                    tipoNome="Video"
                    label1="Link do vídeo da música"
                    label2="Link do vídeo da música"
                    label3="Digite o endereço url do vídeo aqui"
                    value={linkVideo}
                    setValue={setLinkVideo}
                    setError={setErrorLinkVideo}
                    erro={errorLinkVideo}
                    send={send}
                    erro1="Anexe o link do vídeo da música"
                    erro2="Anexe o link do vídeo da música"
                    erro3="Link muito curto"
                  />
                  {/* <InputDropzone
                    icone={<BsCameraVideoFill />}
                    label="Vídeo da música (Formato MP4 até 150MB)"
                    setFile={setVideo}
                    accept={".mp4, video/mp4"}
                    remove={remove}
                    file={video}
                    send={send}
                    erro="Anexe o vídeo da música (Formato: MP4 - Tamanho máximo: 150MB)"
                    setVideoData={setVideoData}
                    durationVideo={durationVideo}
                    videoData={videoData}
                    tamanhoVideo={tamanhoVideo}
                    setTamanhoVideo={setTamanhoVideo}
                    ehVideo={true}
                  /> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDropzone
                    icone={<BsPersonSquare />}
                    label="Foto artística"
                    setFile={setFotoArtistica}
                    accept={"image/*"}
                    remove={remove}
                    file={fotoArtistica}
                    send={send}
                    erro="Anexe uma foto artística"
                  />
                </Grid>
              </Grid>
              <Grid>
                <InputCheckbox termo={termo} setTermo={setTermo} send={send} />
              </Grid>
              <Grid>
                <Buttons
                  termo={termo}
                  setTermo={setTermo}
                  setOpenAlert={setOpenAlert}
                  openAlert={openAlert}
                  setSend={setSend}
                  tipo={tipo}
                  musica={musica}
                  errorNome={errorNome}
                  errorNomeArtisitico={errorNomeArtisitico}
                  errorEmail={errorEmail}
                  errorCPF={errorCPF}
                  errorDtNasc={errorDtNasc}
                  documentoFrente={documentoFrente}
                  documentoVerso={documentoVerso}
                  comprovante={comprovante}
                  letra={letra}
                  video={video}
                  setErro={setErro}
                  nome={nome}
                  nomeArtistico={nomeArtistico}
                  email={email}
                  cpf={cpf}
                  dtNasc={dtNasc}
                  setTipo={setTipo}
                  setTipoNome={setTipoNome}
                  setMusica={setMusica}
                  setMusicaTipo={setMusicaTipo}
                  setNome={setNome}
                  setRemove={setRemove}
                  erro={erro}
                  setErrorNome={setErrorNome}
                  setNomeArtistico={setNomeArtistico}
                  setErrorNomeArtisitico={setErrorNomeArtisitico}
                  setEmail={setEmail}
                  setErrorEmail={setErrorEmail}
                  setCPF={setCPF}
                  setErrorCPF={setErrorCPF}
                  setDtNasc={setDtNasc}
                  setErrorDtNasc={setErrorDtNasc}
                  setDocumentoFrente={setDocumentoFrente}
                  setDocumentoVerso={setDocumentoVerso}
                  setComprovante={setComprovante}
                  setLetra={setLetra}
                  setVideo={setVideo}
                  tipoNome={tipoNome}
                  musicaTipo={musicaTipo}
                  recaptcha={recaptcha}
                  setCidade={setCidade}
                  cidade={cidade}
                  errorCidade={errorCidade}
                  setErrorCidade={setErrorCidade}
                  setRecaptcha={setRecaptcha}
                  errorCelular={errorCelular}
                  setCelular={setCelular}
                  fotoArtistica={fotoArtistica}
                  setFotoArtistica={setFotoArtistica}
                  celular={celular}
                  setSucesso={setSucesso}
                  durationVideo={durationVideo}
                  token={token}
                  setVideoData={setVideoData}
                  setDurationVideo={setDurationVideo}
                  tamanhoVideo={tamanhoVideo}
                  setTamanhoVideo={setTamanhoVideo}
                  videoData={videoData}
                  linkVideo={linkVideo}
                  setLinkVideo={setLinkVideo}
                />
              </Grid>
            </Container>
            <div className="rodape-recaptcha">
              Este site é protegido por reCAPTCHA e a{" "}
              <a href="https://policies.google.com/privacy" className="link">
                Política de privacidade
              </a>{" "}
              e os{" "}
              <a href="https://policies.google.com/terms" className="link">
                Termos de serviço do Google
              </a>{" "}
              se aplicam.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Inscricao;
