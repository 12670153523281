import React from "react";
import Routes from "./routes";
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: 'aff1bce256b84bc28f04a1439ef1d025',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
}

function App() {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Provider>
    );
  }

export default App;
